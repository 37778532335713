const ch = {
  Home: {
    nav: {
      home: "首頁",
      company: "關於品森金融",
      serve: "產品和服務",
      news: "新聞中心",
      broker: "證券經紀",
      invest: "投資研究",
      assets: "資產管理",
    },
    banner: {
      banner1: "成就價值 開拓創新",
      banner2: "企業動態",
      banner3: "一站式證券經紀服務",
      banner4: "專業投資研究服務",
      banner5: "一站式財富管理服務",
      banner6: "法律免責聲明",
      banner7: "隱私政策",
      banner8: "聯繫我們",
      banner9: "企業動態",
    },
    content: {
      top_title1: "品森金融",
      top_title2: "公司關於開展公募基金認購費率優惠活動公告",
      top_time: "2022/12/21 12:34",
      top_p:
        "尊敬的投資者：為答謝廣大投資者長期以來的支持，更好地為投資者提供服務，招商證券股份有限公司（以下簡稱“招商證券”）計畫開展公募基金費率優惠活動。 具體活動範圍說明：適用投資者範圍本活動適用於依據中華人民共和國有關法律法規和基金合同規定可以投資證券投資基金的合法投資者。",
      center_title: "金融科技",
      center_p1: "品森金融是一家金融科技賦能的投資平臺",
      center_p2: "公司始終將金融科技作為公司發展的覈心驅動力之一",
      center_p3: "我們致力於成為亞洲領先的金融服務公司",
      center_p4: "我們有信心成為國際金融市場上的領軍企業",
      center_total:
        "品森金融是一家金融科技賦能的投資平臺，公司始終將金融科技作為公司發展的覈心驅動力之一。我們致力於成為亞洲領先的金融服務公司",
      bottom_title: "一站式服務",
      bottom_p1: "品森金融是一家綜合性金融服務公司",
      bottom_p2: "為個人及機構投資者提供多元化投資服務",
      bottom_p3: "公司業務包括證券經紀和投資研究",
      broker_description1:
        "品森證券經紀業務以科技為金融賦能，依託强大智慧的交易系統，為個人、機構投資者和企業客戶提供港股、美股等全業務鏈的證券經紀服務，滿足客戶多樣化的風險及收益偏好。",
      broker_description2:
        "品森金融為投資者提供優質行情資訊、港股、美股、滬深港通交易服務，幫助投資者緊密跟踪市場動態，適時作出投資決策； 優化流程，實現開戶、出入金、轉股、換匯均可線上提交申請，提供真正優質的服務。",
      invest_description1:
        "品森投研團隊擁有多年研究及投資管理經驗，對資本市場具有較强的投資研究判斷能力，為客戶提供最新全球金融市場資訊和深度分析報告，能够結合客戶需求和風險偏好，在不同市場環境下為客戶提供定制化的投研策略服務，賦能投資決策每一步。",
      invest_description2:
        "品森金融為投資者提供優質行情資訊、港股、美股、滬深港通交易服務，幫助投資者緊密跟踪市場動態，適時作出投資決策； 優化流程，實現開戶、出入金、轉股、換匯均可線上提交申請，提供真正優質的服務。",
      assets_description1:
        "品森資產管理業務打造投研覈心競爭優勢，建立了全面的策略產品體系、完善的風控體系和投資決策流程，秉持“立足長遠，聚焦投研”的理念，為個人、機構投資者和企業客戶提供專業、高效、全面的資產管理服務，助力客戶實現多樣化投融資目標。",
      assets_description2:
        "品森金融提供一站式的資產配寘、全球精選基金交易及投資資訊服務，利用科技將機构級的投資和服務普惠化。 同時，品森金融積極發掘境內外優質資產、捕捉全球稀缺投資機會，為客戶提供定制化的一站式資產管理方案，更好地實現金融服務實體經濟的目標。 堅守“受人之托、代人理財”的資產管理本源，以專業的投資管理能力和個性化的產品研發體系為基礎，為合格投資人提供包括私募債、私募股權、現金管理、特殊機會、不動產投資、對沖基金、全類別公募基金及結構性票據等境內外投資服務。",
      foot_title: "始於香港 直通全球",
      foor_p1: "我們的總部位於香港，旗下平臺可以覆蓋香港、美國等主要金融市場",
      foor_p2: "為客戶提供直通全球資本市場的金融服務。",
    },
    footer: {
      concact: "聯繫我們",
      statement: "法律免責聲明",
      privacy: "隱私政策",
      rights: "版權所有2024 © 品森國際（香港）有限公司",
    },
    title: {
      webTitle: "品森金融",
    },
  },
  Profile: {
    title: {
      main: "公司簡介",
      name: "品森金融",
    },
    content: {
      p1: "品森金融位於國際金融中心香港，於2022年在香港注册成立，是一家綜合性金融服務公司。 公司業務覆蓋香港、美国等全球活躍金融市場，擁有證券經紀、投資研究業務板塊。公司秉承“以客戶為中心”的經營理念，不斷追求深度理解客戶、精准服務客戶，以成就客戶為使命，為個人、機構投資者和企業客戶提供多元金融服務。 公司的主要股東和專業管理團隊對金融與科技行業均擁有豐富經驗與獨特資源，並通過將二者結合，為投資者提供一站式智慧金融服務。",
      // p2:
      //   '品森的主要股東和專業管理團隊對金融及互聯網行業均擁有豐富經驗與獨特資源，並期待將二者結合，為投資者提供創新服務。'
    },
  },
  comBroke: {
    broker: {
      left_text: "品森金融PC版",
      right_text: "品森金融App版",
      for_more: "敬請期待",
    },
    invest: {
      left_text: "嚴謹專業",
      right_text: "深度洞察",
    },
    assets: {
      left_text: "全球投資機會",
      right_text: "專業管理能力",
    },
  },
  newsCenter: {
    title: {
      main1: "一周盤點",
      main2: " 百度集團上周美股跌13.91%港股跌15.65%",
    },
    content: {
      p1: "上周標普500一周漲3.95%，納斯達克綜指漲2.24%，道鐘斯工業平均指數漲5.72%。 百度美股上周累計跌13.91%，上周總成交額28.26億美元，截至上周收盤，美股股價為78.54美元，市值為1687.54億美元； 美股本月累…",
    },
    nav: {
      nav1: "企業動態",
    },
    article: {
      title1: "個股走勢",
      t1_p1:
        "上周標普500一周漲3.95%，納斯達克綜指漲2.24%，道鐘斯工業平均指數漲5.72%。",
      t1_p2:
        "百度美股上周累計跌13.91%，上周總成交額28.26億美元，截至上周收盤，美股股價為78.54美元，市值為1687.54億美元； 美股本月累計跌幅33.15%，今年累計跌幅47.21%，近52周累計跌幅51.59%",
      t1_p3: "上周恒生指數一周跌8.32%，恒生科技指數跌8.98%。",
      t1_p4:
        "百度集團-SW港股上周累計跌15.65%，上周總成交額48.34億港元，截至上周收盤，港股股價為75.70港元，市值為2092.60億港元； 港股本月累計跌33.65%，今年累計跌47.65%，近52周跌53.27%。",
      title2: "相關新聞",
      t2_p1: "VR產業盛會即將召開，華為百度等巨頭參展",
      t2_p2:
        "據證券時報，2022世界VR產業大會將於11月5日全面籌備就緒，11月12日將在南昌召開。 據江西日報，2022世界VR產業大會籌備工作調度推進會發佈消息稱，預計10月底，大會各項籌備工作基本就緒，11月5日全面就緒。 大會正加快推進元宇宙會場、沉浸式空間特效演藝、虛擬數字人等創新場景製作，確保大會期間精彩亮相。 2022世界VR產業大會由工信部、江西省人民政府主辦，將於11月12日至13日在南昌舉行。 從2018年開始，世界VR產業大會已連續在江西舉辦四届。",
      t2_p3: "集度ROBO-01首臺驗證樣車下線高階智慧駕駛研發已開啟路測",
      t2_p4:
        "集度汽車今日在微博宣佈，汽車機器人ROBO-01首臺驗證樣車已於近日完成試製，並於吉利汽車研究院試製中心正式下線。 另外，集度高階智慧駕駛研發已於10月初開啟上海地區的都市域及高速域測試，進入多城場景泛化階段。",
      title3: "美股評級",
      t3_p1: "10月28日Barclays給予百度增持評級，目標價139.00美元。",
    },
  },
  Statement: {
    title: {
      main: "重要法律資訊及使用條款",
      title1: "使用條款",
      title2: "嚴格地限制於合法的使用及進入",
      title3: "不提供金融服務或產品",
      title4: "不作出保證",
      title5: "風險警示",
      title6: "內容的使用",
      title7: "版權及商標",
      title8: "無推薦建議或意見",
      title9: "不對相關科技或知識產權作出保證",
      title10: "豁免責任",
      title11: "控制範圍以外的事件",
      title12: "連結網站",
      title13: "閣下提交的資料",
      title14: "個人資料私隱—如適用",
      title15: "司法權區、可分割性",
    },
    content: {
      t1_p1:
        "務請細閱此等使用條款（「條款」）。 本網站（「本網站」）由品森國際（香港）有限公司擁有，並代表品森國際（香港）有限公司及品森國際其他成員公司（統稱「公司」）運作及維護。 於此等條款中對「公司」的提述亦包括屬公司的授權代表或代理的人士及個人。",
      t1_p2:
        "當閣下使用或進入本網站及其任何頁面時，即表示閣下已明確地同意此等條款，此等條款可由公司全權酌情不時更改，而無需向閣下發出事先通知。 每次閣下進入或使用本網站，閣下受於登入及使用時有效的條款（經修訂、補充及更改）約束，而閣下進入及使用本網站亦受該等條款所規限。",
      t2_p1:
        "本網站僅於進入及使用本網站屬合法以及在任何方面不受適用法律、法規、行為守則、任何有關證券交易所規則（包括上市規則）或任何監管、執行、中央銀行、司法或其他政府機關或機构的規定或命令（統稱「適用法律」）限制的情况及司法權區提供予使用者。 當閣下使用或進入本網站時，即代表閣下的使用及進入屬合法，且閣下承諾閣下僅就合法用途以合法管道使用及進入本網站。",
      t3_p1:
        "公司並不透過本網站提供任何金融服務或產品。 本網站中直接或間接所指的任何產品或服務受獨立的協定、發售檔案及／或申請表格以及公司規定的審批及驗收程式（包括適用法律下所規定者）所規限。",
      t3_p2:
        "本網站內對產品或服務的任何提述並不構成公司所提供的任何產品或服務的要約、邀請、招攬、誘使或推薦建議。",
      t3_p3:
        "本網站不應被視為於有關公司獲授權進行有關活動以外的任何司法權區（以下稱為「獲授權國家」）參與投資活動的任何邀請或誘使或買賣任何證券或其他工具的任何要約之傳遞途徑。",
      t3_p4:
        "除非另有所指，本網站內所指的服務及產品並無於獲授權國家或閣下所居住國家的任何中央銀行、政府或監管機關登記或獲其認可。 囙此，就於或透過本網站所指的產品或服務而言，閣下可能不受閣下居住國家的證券法律、銀行法律及其他相關法律法規的保障。",
      t3_p5:
        "概不對在本網站內所討論或透過本網站得到的任何證券、產品或服務適合閣下或任何個別人士作出任何聲明。 閣下確認閣下使用本網站及因瀏覽本網站而作出的任何資訊要求並非為公司所徵求，而透過本網站提供任何數據並不構成亦不應被視為投資或財務意見。",
      t3_p6:
        "於此等頁面所載的數據並無意提供專業意見。 進入此等頁面的人士於必要時應就有關任何投資或交易的任何决定取得適當的專業意見。",
      t4_p1:
        "儘管於編制本網站所載資訊及數據時已審慎行事，有關資訊及數據以其「現狀」提供，並無作出任何明確或隱含的保證。 特別是，概無就有關資訊及數據並無侵權、安全、準確性、適用於特定目的或不含電腦病毒作出保證。",
      t4_p2:
        "本網站所指的任何數據及內容或產品或服務可在公司酌情决定下隨時被撤回（全部或部分）或修訂、更新、更改或移除，而無需發出事先通知。",
      t4_p3:
        "公司於本網站登載的資料代表公司及其人員現時的意見，而有關意見可在無通知下予以更改。 資料僅供參考，並不應被視為任何個別證券、策略或投資產品的投資意見或推薦建議。",
      t4_p4:
        "在此所載的若干陳述可能為根據公司現時意見及假設而作出的未來預期陳述及其他前瞻性陳述，以及涉及已知及未知風險及不明朗因素，而可能導致實際結果、表現或事件與在該等陳述中明示或暗示者有重大差异。 除在文義上屬前瞻性的陳述外，「可、將、應、預期、計畫、有意、預計、相信、估計、預測、潜在或持續」等字眼及相類詞彙亦可用以識別前瞻性陳述。",
      t5_p1:
        "過往表現並不保證未來業績，亦不可作為未來業績的可靠名額。 所有投資均存有風險及可損失價值。 投資於債券市場需承受若干風險，包括市場、利率、發行人、信貸及通脹風險。 股票價值可由於實際及所認為的一般市場、經濟及行業狀況而下跌。 投資於外幣計價及／或境外證券可能由於匯率波動及經濟和政治風險（於新興市場可能較高）而涉及較高風險。 投資的價值及所得收益可昇亦可跌，閣下可能無法收回原始投資額。 倘投資涉及貨幣風險，匯率變動可導致投資價值上升或下跌。 本網站內所指的任何稅務優惠可予更改，而其可用性及價值將視乎閣下的個別情况而定。 有關投資並非任何銀行的存款或義務或受任何銀行擔保或認可，亦無獲聯邦保險或獲任何政府機構、中央銀行、法定機關或任何其他人士擔保。 概不保證於本網站中所討論的任何投資策略於所有市况下均有效或適合所有投資者，而各投資者應評估他們的長遠投資能力，特別是於市場下跌期間。 投資者應於作出任何投資决定前諮詢其財務及／或稅務顧問。 本網站中的某些投資及服務於退休儲蓄計畫中可能並無提供或不適合有關計畫。 請就與閣下個別計畫有關的任何投資限制聯絡閣下的財務顧問。",
      t6_p1:
        "本網站及本網站所載的任何內容及資料僅供參考，並不構成有關的公司明示或暗示地提供服務或產品。",
      t7_p1:
        "本網站（包括但不限於任何軟件、編碼、算灋及方法）、代管本網站的電腦及本網站中所提供的內容（包括任何文字、音樂和聲音、影像、影像、相片、音訊和視頻、影片、動畫、網路廣播、分析、研究、報告及下載）（所有有關內容統稱「內容」）為公司的專有財產或公司取得許可， 並受適用法律（包括香港的資料庫、商業秘密及商標法律）保障。 「公司」及相關名稱、文字、符號、影像及印刷品為公司於香港的商標或注冊商標。 公司對其於本網站的專有資訊或數據保留一切權利，並會在適用版權及商標法律的最大範圍內執行有關權利。 除此等條款明確規定者外，公司並無向閣下授予任何明確或隱含權利。 本網站的所有權利及內容由公司及其有關的許可人擁有。 閣下同意在沒有公司的明確書面同意下不以任何管道出售、分發、刊發、廣播、傳閱或商業地利用有關資料。 從本網站下載數據或以其他管道複製數據並不會將任何軟件或數據的所有權轉讓予閣下。 除非另有指明，公司及於本網站所介紹的其他品牌構成我們的商標／服務標誌。 閣下同意不會直接或間接：試圖登記、懷疑或質疑公司對有關版權、商標或任何公司的任何其他知識產權所有權的有效性或協助協力廠商作出有關行動。",
      t8_p1:
        "對特定證券及其發行人的提述並無意及不應被詮釋為買賣或持有有關證券的推薦建議。 公司產品及策略可能或可能不會包括所提述證券，及倘包括有關證券，亦不對將持續包括有關證券作出聲明。",
      t9_p1:
        "本網站（包括超連結或對其他網站的提述）所載的數據及意見以其「現狀」提供，在適用法律所允許的最大範圍內並無作出任何明確或隱含的保證。 閣下負責評估本網站所提供的任何數據或內容的足够性、準確性、可靠性、適銷性、非侵權性、完整性或網站或其任何內容對任何特定目的的合適性。 公司亦不對本網站所載功能將為不間斷或無誤、不足之處將加以改正或本網站或支持本網站運作的服務器不含病毒或其他有害組件承擔任何責任及作出任何保證。",
      t10_p1:
        "公司或其各自的任何聯屬公司、董事、高級職員、注册代表或雇員或任何協力廠商賣方在合約、侵權行為、嚴格責任或其他方面均無需就閣下進入或使用或未能進入或使用本網站或倚賴內容或與本網站有關的效能故障、中斷、不足之處、傳輸延誤、 電腦病毒或其他有害組件或線路或系統故障（不論我們是否知悉）所產生或在任何管道上有關的任何直接、間接、附帶、隨之發生、懲罰性或特殊損害承擔或有任何法律責任。",
      t11_p1:
        "閣下明確地免除及解除公司、其各自的聯屬公司、相關公司及其各自所有的高級職員、董事、雇員、注册代表、股東、法定代表、律師、代理、 繼任人及受讓人因其控制範圍以外的原因（包括但不限於電子或機械設備或通訊線路故障、電話或其他互連問題、電腦病毒或軟件故障、未經授權下存取、盜竊、操作員錯誤、惡劣天氣、地震或天灾、罷工或其他勞工問題、戰爭、國內或國外的恐怖主義活動或政府限制）而造成傷害的索償。",
      t12_p1:
        "本網站內若干超連結或所提述網站可能是為閣下方便起見而將閣下轉至協力廠商網站，該等網站一般可透過其高級功能變數名稱辨認得到。 除非公司明確列明，否則對其他產品、刊物或服務的任何描述、提述或連結並不構成公司對任何連結網站或其贊助人的認可、授權、贊助或聯系。 任何有關資料、產品或網站並不一定經公司審閱及由公司並無控制權的協力廠商提供或維持。 公司明確地拒絕就該等協力廠商網站所提供或文宣的內容、數據準確性及／或產品或服務質素承擔任何責任。 公司保留權利隨時終止任何超連結或超連結程式。",
      t13_p1:
        "閣下不應而公司亦不接受透過本網站或任何論壇提交任何機密或專屬資料。 透過本網站提交的所有資訊或數據將被視為公司財產。 透過本網站將任何資訊或數據提交予我們，即代表閣下向我們授予無限制、不可撤回許可，允許我們使用、複製、展示、執行、修改、傳輸及分發該等數據或資訊。 閣下亦同意公司可自由就任何目的使用閣下透過本網站向我們提交的意念、概念、專有技術或技巧。 公司無需就所提交資訊受任何保密責任所規限，惟經公司同意有直接客戶關係或以其他管道特別協定或適用法律所規定者則除外。",
      t14_p1:
        "倘閣下向公司提供任何個人資料，公司的私隱政策將適用，閣下將獲提供該政策副本。",
      t15_p1:
        "此等條款或本網站所產生的任何訴訟必須及僅可在中華人民共和國香港特別行政區（「香港」）法院提出，且閣下同意受香港法院的專有司法管轄權管轄，並進一步同意該等法院對閣下而言屬方便。 倘此等條款的任何條文不能強制執行，餘下條文的有效性或強制執行性並不會受影響，而不能強制執行條文將以與不能強制執行條文意向接近的可強制執行條文所取代。",
    },
  },
  Privacy: {
    title: {
      main1: "用戶私隱政策",
      main2: "用户隐私政策（中国大陆）",
      title1: "1. 序言",
      title2: "2. 我們何時收集何種個人資料",
      title3: "3. 我們將個人資料作何種用途？",
      title4: "4. 我們如何使用小型文字檔（cookies）",
      title5: "5. 我們是否會將個人資料用於直接促銷？",
      title6: "6. 我們向何人披露個人資料",
      title7: "7. 如何確保個人資料安全？",
      title8: "8. 查閱及更正 / 査詢您的個人資料",
      title9: "個人資料收集聲明",
    },
    content: {
      t1_p1:
        "品森金融（「我們」或「品森金融」）致力根據香港《個人資料（私隱）條例》（“私隱條例”）中載列的規定保護個人資料。",
      t1_p2:
        "我們將僅按照《個人資料（私隱）條例》及本私隱政策收集、使用或披露個人資料。",
      t1_p3: "我們可出於任何原因隨時對本私隱政策進行修改。",
      t1_p4: "在本私隱政策中，「個人資料」指符合以下說明的任何資料：",
      t1_p5: "直接或間接與一名在世的個人有關的；",
      t1_p6:
        "該項資料是切實可行的用於直接或間接地確定有關個人的身份； 及該項數據的形式是切實可行的讓人可查閱及處理該項數據。",
      // t1_p7: '該項數據的形式是切實可行的讓人可查閱及處理該項數據。',
      t2_p1:
        "我們收集的個人資料的類型將視乎所收集資料的具體情況而定。 倘若您未有提供我們所要求的個人資料，我們未必能够向您提供或繼續提供產品和服務。",
      t2_p2: "我們可在以下情况下收集關於您的個人資料:",
      t2_p3:
        "使用我們的網絡平臺時——包括您的身份數據和聯絡詳情、生物識別資料、金融資料、您的收入和現有投資；",
      t2_p4: "向我們申請開設帳戶時——包括您的金融資料、收入及現有投資資料；",
      t2_p5:
        "•是在我們開設的帳戶的企業簽署人、董事、高級人員或擔保人時——包括您的身份資料和聯絡詳情；",
      t2_p6:
        "•應聘我們的職位時——包括您的身份數據和聯絡詳情、您的技能、您可能存在的任何殘疾或身體狀況、發放薪水的銀行帳戶資料、購買醫療保險所需的家庭成員詳情、您的犯罪記錄數據及與我們的合規義務相關的其他數據； 或",
      t2_p7: "•向我們發送信函時——包括您的詳細聯絡方式，以便我們回復您。",
      t3_p1: "您的個人資料的用途將視乎所收集資料的具體情況而定。",
      t3_p2:
        "我們將於收集您的個人資料之時或之前在「個人資料收集聲明」中告知您我們將使用您的個人資料作何用途。",
      t3_p3: "一般而言，我們會將您的個人資料用於：",
      t3_p4: "•您向我們提供個人資料的目的；",
      t3_p5: "•與您向我們提供個人資料之目的直接相關的用途；",
      t3_p6: "•您所同意的任何其他用途； 及",
      t3_p7:
        "•遵守對我們產生約束力的任何法律及法規，以及任何法律、監管、政府、稅務、執法或其他機關，或與我們及我們的產品和服務有關的自我監管或行業組織或協會發出的任何指引或通知。",
      t3_p8: "例如，我們可能在以下情况下透露您的個人資料：",
      t3_p9:
        "•使用我們的網絡平臺時——用於處理和評估您的申請、開設和管理帳戶、核實您的身份、為您提供服務、進行信用審查和向您提供信貸融通、協助其他金融機構進行信用審查、向您收取及支付款項、為您的責任提供擔保、設計新的金融服務和產品、偵測、調查及預防詐騙或刑事活動， 以及作出法律規定或允許的任何披露或轉讓；",
      t3_p10:
        "•向我們申請開設帳戶時——用於處理和評估您的申請、開設和管理帳戶、為您提供服務、進行信用審查和向您提供信貸融通、協助其他金融機構進行信用審查、向您收取及支付款項、為您的責任提供擔保、設計新的金融服務和產品，以及作出法律規定或允許的任何披露或轉讓；",
      t3_p11:
        "•是在我們開設的帳戶的企業簽署人、董事、高級人員或擔保人時——用於將您注册為簽署人或擔保人並遵守法律規定；",
      t3_p12:
        "•應聘我們的職位時——用於評估您的申請、管理薪酬、福利和稅務、表現評估、晋昇、處理紀律問題、編制應變計畫、培訓、人才招聘、多樣性規劃、向協力廠商提供推薦信、員工內部重組，以及遵守法律規定，以及作出法律規定或允許的任何披露或轉讓； 及",
      t3_p13: "•向我們發送信函——用於回復您。",
      t4_p1:
        "通過進入我們的網絡平臺，您承認您已被告知使用小型文字檔（cookies）的常規，並授權我們就本私隱政策所設定的目的使用通過我們使用小型文字檔（cookies）所收集的任何資料。 “小型文字檔（cookies）”是當您進入某些網站或電話應用程序後儲存在您的電子設備（例如電腦或電話）的數據。",
      t4_p2:
        "小型文字檔（cookies）主要用於當訪客返回同一個網站時，他們不需要提供先前已經提供予該網站的某些資訊。 小型文字檔（cookies）也可用來收集顯示一個網站的領域是否常被瀏覽的數據。 保留有關網站經常被瀏覽的領域的數據可允許操作員更好地規劃和提升該網站。",
      t4_p3:
        "我們明白您可能會希望禁用小型文字檔。 您可以透過改變您的瀏覽器設定來禁用小型文字檔（cookies），但此舉可能會限制更多功能。 但是，如果您這樣做，您可能無法使用或啟動我們的網絡平臺上的某些功能。",
      t5_p1:
        "我們會透過電話行銷、電子形式、直接郵寄或其他適當的手段將收集的部分個人資料用於向預期接收者發送市場推廣資料及特別優惠資訊，但我們僅會在遵守私隱條例所規定有關直接促銷的規則前提下使用個人資料。",
      t5_p2:
        "若我們有意將您的個人資料用於直接促銷或提供您的個人資料以供協力廠商用於直接促銷，我們將會於收集您的個人資料之時或之前在「個人資料收集聲明」中告知您。 届時，您可以選擇拒絕接收直接促銷資訊。",
      t5_p3:
        "若當時您未選擇拒絕接收直接促銷資訊，但其後確定不希望接收直接促銷資訊，您可以聯絡數據保護專員，要求我們停止向您發送任何進一步直接促銷資訊。",
      t6_p1: "您的個人資料將獲被披露到的協力廠商將視乎個人資料的用途而定。",
      t6_p2:
        "我們將於收集您的個人資料之時或之前，在「個人資料收集聲明」中告知您我們將向其披露您的個人資料的協力廠商。",
      t6_p3: "一般而言，我們會就以下目的按需要披露您的個人資料：",
      t6_p4: "•您向我們提供個人資料的目的；",
      t6_p5: "•與您向我們提供個人資料之直接相關的目的； 及",
      t6_p6: "•您所同意的任何其他目的。",
      t6_p7: "例如，我們可能會向以下人士披露您的個人資料：",
      t6_p8:
        "•提供行政管理、通訊、電腦、支付、警衛或其他服務以協助我們實現上述目的之服務的協力廠商代理人、承辦商及顧問（包括電話銷售員、寄件中心、資訊科技服務供應商及資料處理人員）；",
      t6_p9: "•我們的法律及專業顧問；",
      t6_p10: "•我們的關連公司（定義見香港《公司條例》）；",
      t6_p11:
        "•對我們或我們的關連公司具約束力的任何法律、法規、規則或準則所規定之政府機構及相關當局； 及",
      t6_p12: "•您所同意的任何其他人士。",
      t6_p13:
        "當個人資料就此類目的被轉移到香港以外的地方時，該地方可能會或可能不會提供與香港相同或類似的個人資料保護。",
      t7_p1:
        "我們將採取一切切實可行的步驟，以確保您的個人資料不會被擅自查閱、披露、處理、删除、遺失或使用。",
      t8_p1:
        "您可以聯絡我們請求查閱或更正或査詢我們所持有的關於您的個人資料。 《個人資料（私隱）條例》中的若干豁免情况適用於查閱及更正個人資料的要求。 我們可能會要求您（即要求查閱或更正數據的人士）提供適當的身份證明，並且可能會收取合理的行政費用，方會準予您查閱資料的要求。",
      t8_p2:
        "若需要查閱或更正或査詢我們所持有的個人資料，請透過以下管道聯絡我們：",
      t8_p3: "數據保護專員",
      t8_p4: "品森國際",
      t8_p5: "香港地址：香港九龍灣宏照道39號企業廣場3期39樓",
      // 香港灣仔告士打道38號中國恒大中心18樓1803-1806室
      t9_p1:
        "品森國際（香港）有限公司（「我們」或「品森國際」）可不時出於以下目的使用我們所收集到有關於您的個人資料：",
      t9_p2: "",
      t9_p3: "•辦理及評估您的帳戶申請；",
      t9_p4: "•為您開設及管理帳戶；",
      t9_p5: "•核實您的身份；",
      t9_p6: "•偵測、調查及預防詐騙或刑事活動；",
      t9_p7: "•提供財富管理及投資服務；",
      t9_p8: "•進行信用審查及提供信貸融通；",
      t9_p9: "•協助其他金融機構進行信用審查；",
      t9_p10: "•厘定欠付您或您欠付的未償付金額；",
      t9_p11: "•向您收取款項及未償付金額；",
      t9_p12: "•向您支付股息、利息、收益及其他金額；",
      t9_p13: "•為您的責任提供擔保；",
      t9_p14: "•出於以上任何目的聯絡您；",
      t9_p15: "•設計新金融服務及產品；",
      t9_p16:
        "•根據對我們或我們的關連公司具約束力的任何法律、法規、規則或準則規定作出披露",
      t9_p17: "•與上述目的直接相關的其他次要目的； 及",
      t9_p18: "•您所同意的任何其他用途。",
      t9_p19:
        "在您同意的情况下，我們還可使用您的聯絡詳情、人口統計資訊及投資詳情，以郵件、電子郵件、電話、短訊、實时通訊或推播通知管道向您發送有關金融及投資產品或服務的市場推廣通訊。 我們需要您的同意。 您可以以任何退出選項表明您不同意接收此類市場推廣通訊。",
      t9_p20:
        "在您同意的情况下，我們還可將您的聯絡詳情、人口統計資訊及投資詳情提供予我們關聯公司，保險公司及/或投資經紀（當中可能獲得收益），他們可能以郵件、電子郵件、電話、短訊、實时通訊或推播通知管道向您發送有關金融及投資產品或服務的市場推廣通訊。 我們需要您的同意。 您可以以任何退出選項表明您不同意我們將您的個人資料提供給出了我們關聯公司之外的協力廠商，或不希望接收來自此類協力廠商市場推廣通訊。",
      t9_p21:
        "我們亦會不時使用你的名稱、聯繫方式、產品和服務、投資詳情、投資組合資訊、交易模式及行為、財務背景和人口統計資料作我們、我們的關聯公司和我們的業務合作夥伴可能提供的有關以下類別的產品及服務（包括提供獎賞、忠誠或優惠計畫）之直接促銷：a）保險、年金、金融、財富管理、退休計畫、 投資和金融產品及服務、信用卡、外匯、衍生工具、證券及相關產品及服務； 及b）健康、保健和醫療、食品和飲料、媒體、網絡購物、電子商務、遊戲、博彩、物流、體育活動、會員及相關產品和服務。",
      t9_p22: "我們可就上述目的將您的個人資料披露予：",
      t9_p23:
        "•提供行政管理、通訊、電腦、支付款項、警衛或其他服務以協助我們實現上述目的之協力廠商代理人、承辦商及顧問（包括電話銷售員、寄件中心、資訊科技服務供應商及資料處理人員）；",
      t9_p24: "•您與之或打算與之進行交易的任何金融機構；",
      t9_p25: "•我們與您相關的權利的實際或建議承讓人、受讓人或參與者；",
      t9_p26: "•我們的法律及專業顧問；",
      t9_p27: "•我們的關連公司（定義見香港《公司條例》）；",
      t9_p28:
        "•我們的商業夥伴及/或其關聯公司提供的上述產品和服務，包括獎賞、忠誠或優惠計畫的協力廠商提供商； 支持我們或任何上述實體提供上述產品和服務的外部服務提供者；",
      t9_p29:
        "•對我們或我們的關連公司具約束力的任何法律、法規、規則或準則所規定之政府機構及相關當局； 及",
      t9_p39: "•您所同意的任何其他人士。",
      t9_p30:
        "除了上述產品及服務的市場行銷，我們還可將您的上述個人資料提供及/或傳遞予上述之全部或任何實體或人員，他們可能以郵件、電子郵件、電話、短訊、實时通訊或推播通知管道向您發送市場推廣通訊。 我們需要您的同意。 您可以以任何退出選項表明您不同意我們將您的個人資料提供給此類協力廠商，或不希望接收來自此類協力廠商市場推廣通訊。 在某些情况下，我們或我們向其披露您的個人資料的協力廠商可在香港以外地區處理及儲存您的個人資料。",
      t9_p31:
        "就網絡平臺（包括任何移動應用程序及網站），某些欄位將標明可選擇是否提供個人資料之項目。 倘若您未提供必填欄目要求填寫的個人資料，我們將無法向您提供服務。",
      t9_p32:
        "除非文義另有說明或另有規定，否則本個人資料收集聲明中使用的黑體字詞語應與本協定中定義的含義相同。",
      t9_p33:
        "您可透過以下聯絡方式，要求查閱或要求更正我們所持有的關於您的任何個人資料：",
      t9_p34: "數據保護專員",
      t9_p35: "品森國際",
      t9_p36: "香港地址：",
      // 香港灣仔告士打道38號中國恒大中心18樓1803-1806室
      t9_p37: "版本發佈日期：2022年11月10日",
      t9_p38: "版本生效日期：2022年11月10日",
    },
  },
  Contact: {
    content: {
      company: "品森國際（香港）有限公司",
      address: "香港九龍灣宏照道39號企業廣場3期39樓",
    },
  },
  New: {
    sub_title: "品森金融",
    title1: "美股冬令時交易時間切換提醒",
    title2: "品森國際舉辦2023年度第壹季度投資策略研討會",
    title3: "3月12日起美國進入夏令時，美股開盤時間提前至21:30",
    title4: "美元及美國國債收益率上升 國際金價下跌",
    title5: "鮑威爾：美聯儲堅定致力於實現2%的目標",
    title6: "港交所上半年IPO數量增募資減",
    title7: "北美地區已開始實行夏令時 美股將於21:30開盤",
    title8: "美聯儲古爾斯比：預計今年將降息三次",
    title9: "港股需等待更多積極信號，建議仍以高股息策略爲主",
    con_p1:
      "<p>尊敬的客戶，美國將於11月6日（周日）開始冬令時，交易時間調整如下：</p><p>美股交易時間為北京时間22:30至次日淩晨5:00；</p><p>盤前交易時間為北京时間17:00至22:30；</p><p>盤後交易時間為北京时間05:00至09:00；</p><p>美國期貨交易時間也對應調整為延後1小時。</p><p>敬請知悉。</p>",
    con_p_title1:
      "<p>尊敬的客戶，美國將於11月6日（周日）開始冬令時，交易時間調整如下：</p><p>美股交易時間為北京时間22:30至次日淩晨5:00；</p><p>盤前交易時間為北京时間17:00至22:30；</p><p>敬請知悉。</p>",
    con_p2:
      "<p>為提升對廣大機構投資者的投研服務質量，品森國際於1月8日在香港舉行了2023年度第一季度證券投資策略研討會。 會議由劉總主持，首席行業分析師及幾比特資深研究員分別作了各行業2023年度第一季度的策略研究報告，報告會獲得圓滿成功。</p>",
    con_p_title2:
      "<p>為提升對廣大機構投資者的投研服務質量，品森國際於1月8日在香港舉行了2023年度第一季度證券投資策略研討會。 會議由劉總主持，首席行業分析師及幾比特資深研究員分別作了各行業2023年度第一季度的策略研究報告，報告會獲得圓滿成功。</p>",
    con_p3:
      "<p>3月12日（周日）起，美國進入夏令時，美股提前一小時開盤。</p><p>具體交易時間調整如下：</p><p>美股持續交易時間調整為21:30-次日04:00（北京時間）</p><p>盤前交易為16:00-21:30（北京時間）</p><p>盤後交易為04:00-08:00（北京時間）</p>",
    con_p_title3:
      "<p>3月12日（周日）起，美國進入夏令時</p><p>具體交易時間調整如下：</p><p>美股持續交易時間調整為21:30-次日04:00（北京時間）</p>",
    con_p_title4:
      "<p>美元及美國國債收益率上升，國際金價下跌。紐約期金收報每盎司2007美元，下跌約0.4%。</p><p>利率期貨顯示，聯儲局在5月加息25個基點的可能性為86%。</p>",
    con_p5:
      "<p>美聯儲主席鮑威爾：通脹目前遠遠超過美聯儲的目標，通貨膨脹對社會邊緣人群造成重大困難。美聯儲堅定致力於實現2%的目標；未能實現將會造成更大的傷害。</p",
    con_p5_sub:
      "<p>美聯儲主席鮑威爾：通脹目前遠遠超過美聯儲的目標，通貨膨脹對社會邊緣人群造成重大困難。美聯儲堅定致力於實現2%的目標；未能實現將會造成更大的傷害。</p",
    con_p_title5:
      "<p>美聯儲主席鮑威爾：通脹目前遠遠超過美聯儲的目標，通貨膨脹對社會邊緣人群造成重大困難。</p><p>美聯儲堅定致力於實現2%的目標；未能實現將會造成更大的傷害。</p>",
    con_p_title6:
      "<p>上半年進入尾聲。Choice數據顯示，年初至今，港股IPO數量為28只（包括介紹上市），募資總額為162.62億港元，同比減少14.63%。2022年同期，港股有22只新股上市（包括介紹上市），募資總額達190.49億港元。</p>",
    con_p6:
      "<p>上半年進入尾聲。Choice數據顯示，年初至今，港股IPO數量為28只（包括介紹上市），募資總額為162.62億港元，同比減少14.63%。2022年同期，港股有22只新股上市（包括介紹上市），募資總額達190.49億港元。</p><p>展望下半年，業內人士認為，多家互聯網巨頭分拆子業務獨立上市、特專科技公司陸續上市將成港股IPO重要力量。</p><p>普華永道香港資本市場服務合夥人黃金錢預計，今年或有5家至10家特專科技公司按照新規上市，平均募資約50億港元。</p>",
    con_p6_sub:
      "<p>上半年進入尾聲。Choice數據顯示，年初至今，港股IPO數量為28只（包括介紹上市），募資總額為162.62億港元，同比減少14.63%。2022年同期，港股有22只新股上市（包括介紹上市），募資總額達190.49億港元。</p><p>展望下半年，業內人士認為，多家互聯網巨頭分拆子業務獨立上市、特專科技公司陸續上市將成港股IPO重要力量...</p>",
    con_p_title7:
      "<p>北美地區已開始實行夏令時，美國和加拿大金融市場交易時間（金銀、美油、美股等）和經濟資料公佈時間將較冬令時提前一小時，即金銀、美油將於北京时間6:00開盤，美股將於北京时間21:30開盤，次日04:00收盤。</p>",
    con_p7:
      "<p>北美地區已開始實行夏令時，美國和加拿大金融市場交易時間（金銀、美油、美股等）和經濟資料公佈時間將較冬令時提前一小時，即金銀、美油將於北京时間6:00開盤，美股將於北京时間21:30開盤，次日04:00收盤。</p>",
    con_p7_sub:
      "<p>北美地區已開始實行夏令時，美國和加拿大金融市場交易時間（金銀、美油、美股等）和經濟資料公佈時間將較冬令時提前一小時，即金銀、美油將於北京时間6:00開盤，美股將於北京时間21:30開盤，次日04:00收盤。</p>",
    con_p_title8:
      "<p>芝加哥聯邦儲備銀行行長奧斯丁·古爾斯比周一表示，在上週的聯邦儲備委員會政策會議上，他預計今年將有三次降息。在那次會議上，美聯儲將基準隔夜貸款利率維持在5.25%-5.5%的目標區間，而政策制定者預計今年降息的中位數爲3。古爾斯比在接受媒體採訪時表示：“我的預測處...</p>",
    con_p8:
      "<p>芝加哥聯邦儲備銀行行長奧斯丁·古爾斯比周一表示，在上週的聯邦儲備委員會政策會議上，他預計今年將有三次降息。在那次會議上，美聯儲將基準隔夜貸款利率維持在5.25%-5.5%的目標區間，而政策制定者預計今年降息的中位數爲3。</p><br/><p>古爾斯比在接受媒體採訪時表示：“我的預測處於中位數。”但他拒絕透露他認爲借貸成本可能何時開始放鬆。古爾斯比補充說，一月和二月的價格上漲數據高於預期，“主要難題”仍然是住房通脹。</p><br/><p>古爾斯比說：“所以我們處於一個不確定的狀態，但我覺得我們並沒有從根本上改變我們正在回到目標的情況。”</p>",
    con_p8_sub:
      "<p>芝加哥聯邦儲備銀行行長奧斯丁·古爾斯比周一表示，在上週的聯邦儲備委員會政策會議上，他預計今年將有三次降息。在那次會議上，美聯儲將基準隔夜貸款利率維持在5.25%-5.5%的目標區間，而政策制定者預計今年降息的中位數爲3。</p><p>古爾斯比在接受媒體採訪時表示：“我的預測處於中位數。”但他拒絕透露他認爲借貸成本可能何時開始放鬆。古爾斯比補充說，一月和二月的價格上漲數據高於預期，“主要難題”仍然是住房通脹...</p>",
    con_p_title9:
      "<p>天風證券發佈研究報告稱，港股市場方面，在經濟數據持續性等待驗證以及港元貶值壓力尚未緩解的背景下，建議仍以高股息策略爲主。短期內股息率較高的公用事業、能源、金融、電信等板塊，即使未來市場波動率有所上升，也能在該環境中提供可觀的相對收益；中長期而言，以半...</p>",
    con_p9:
      "<p>天風證券發佈研究報告稱，港股市場方面，在經濟數據持續性等待驗證以及港元貶值壓力尚未緩解的背景下，建議仍以高股息策略爲主。短期內股息率較高的公用事業、能源、金融、電信等板塊，即使未來市場波動率有所上升，也能在該環境中提供可觀的相對收益；中長期而言，以半導體、互聯網爲代表的科技行業仍將是產業轉型的主抓手，有望從政府支持以及國產替代中受益。</p>",
    con_p9_sub:
      "<p>天風證券發佈研究報告稱，港股市場方面，在經濟數據持續性等待驗證以及港元貶值壓力尚未緩解的背景下，建議仍以高股息策略爲主。短期內股息率較高的公用事業、能源、金融、電信等板塊，即使未來市場波動率有所上升，也能在該環境中提供可觀的相對收益；中長期而言，以半導體、互聯網爲代表的科技行業仍將是產業轉型的主抓手，有望從政府支持以及國產替代中受益。</p>",
  },
};

export default ch;
