const ch = {
  Home: {
    nav: {
      home: "首页",
      company: "关于品森金融",
      serve: "产品和服务",
      news: "新闻中心",
      broker: "证券经纪",
      invest: "投资研究",
      assets: "资产管理",
    },
    banner: {
      banner1: "成就价值 开拓创新",
      banner2: "企业动态",
      banner3: "一站式证券经纪服务",
      banner4: "专业投资研究服务",
      banner5: "一站式财富管理服务",
      banner6: "法律免责声明",
      banner7: "隐私政策",
      banner8: "联系我们",
      banner9: "企业动态",
    },
    content: {
      top_title1: "品森金融",
      top_title2: "公司关于开展公募基金认购费率优惠活动公告",
      top_time: "2022/12/21 12:34",
      top_p:
        "尊敬的投资者：为答谢广大投资者长期以来的支持，更好地为投资者提供服务，招商证券股份有限公司（以下简称“招商证券”）计划开展公募基金费率优惠活动。具体活动范围说明：适用投资者范围本活动适用于依据中华人民共和国有关法律法规和基金合同规定可以投资证券投资基金的合法投资者。",
      center_title: "金融科技",
      center_p1: "品森金融是一家金融科技赋能的投资平台",
      center_p2: "公司始终将金融科技作为公司发展的核心驱动力之一",
      center_p3: "我们致力于成为亚洲领先的金融服务公司",
      // center_p4: "我们有信心成为国际金融市场上的领军企业",
      center_total:
        "品森金融是一家金融科技赋能的投融资平台，公司始终将金融科技作为公司发展的核心驱动力之一。我们致力于成为亚洲领先的金融服务公司。",
      bottom_title: "一站式服务",
      bottom_p1: "品森金融是一家综合性金融服务公司",
      bottom_p2: "为个人及机构投资者提供多元化投资服务",
      bottom_p3: "公司业务包括证券经纪和投资研究",
      broker_description1:
        "品森证券经纪业务以科技为金融赋能，依托强大智能的交易系统，为个人、机构投资者和企业客户提供港股、美股等全业务链的证券经纪服务，满足客户多样化的风险及收益偏好。",
      broker_description2:
        "品森金融为投资者提供优质行情资讯、港股、美股、沪深港通交易服务，帮助投资者紧密跟踪市场动态，适时作出投资决策；优化流程，实现开户、出入金、转股、换汇均可在线提交申请，提供真正优质的服务。",
      invest_description1:
        "品森投研团队拥有多年研究及投资管理经验，对资本市场具有较强的投资研究判断能力，为客户提供最新全球金融市场资讯和深度分析报告，能够结合客户需求和风险偏好，在不同市场环境下为客户提供定制化的投研策略服务，赋能投资决策每一步。",
      invest_description2:
        "品森金融为投资者提供优质行情资讯、港股、美股、沪深港通交易服务，帮助投资者紧密跟踪市场动态，适时作出投资决策；优化流程，实现开户、出入金、转股、换汇均可在线提交申请，提供真正优质的服务。",
      assets_description1:
        "品森资产管理业务打造投研核心竞争优势，建立了全面的策略产品体系、完善的风控体系和投资决策流程，秉持“立足长远，聚焦投研”的理念，为个人、机构投资者和企业客户提供专业、高效、全面的资产管理服务，助力客户实现多样化投融资目标。",
      assets_description2:
        "品森金融提供一站式的资产配置、全球精选基金交易及投资资讯服务，利用科技将机构级的投资和服务普惠化。同时，品森金融积极发掘境内外优质资产、捕捉全球稀缺投资机会，为客户提供定制化的一站式资产管理方案，更好地实现金融服务实体经济的目标。坚守“受人之托、代人理财”的资产管理本源，以专业的投资管理能力和个性化的产品研发体系为基础，为合格投资人提供包括私募债、私募股权、现金管理、特殊机会、不动产投资、对冲基金、全类别公募基金及结构性票据等境内外投资服务。",
      foot_title: "始于香港 直通全球",
      foor_p1: "我们的总部位于香港，旗下平台可以覆盖香港、美国等主要金融市场",
      foor_p2: "为客户提供直通全球资本市场的金融服务。",
    },
    footer: {
      concact: "联系我们",
      statement: "法律免责声明",
      privacy: "隐私政策",
      rights: "版权所有 2024©品森国际（香港）有限公司",
    },
    title: {
      webTitle: "品森金融",
    },
  },
  Profile: {
    title: {
      main: "公司简介",
      name: "品森金融",
    },
    content: {
      p1: "品森金融位于国际金融中心香港，于2022年在香港注册成立，是一家综合性金融服务公司。公司业务覆盖香港、美国等全球活跃金融市场，拥有证券经纪、投资研究业务板块。公司秉承“以客户为中心”的经营理念，不断追求深度理解客户、精准服务客户，以成就客户为使命，为个人、机构投资者和企业客户提供多元金融服务。公司的主要股东和专业管理团队对金融与科技行业均拥有丰富经验与独特资源，并通过将二者结合，为投资者提供一站式智慧金融服务。",
      // p2: "品森的主要股东和专业管理团队对金融及互联网行业均拥有丰富经验与独特资源，并期待将二者结合，为投资者提供创新服务。",
    },
  },
  comBroke: {
    broker: {
      left_text: "品森金融PC版",
      right_text: "品森金融App版",
      for_more: "敬请期待",
    },
    invest: {
      left_text: "严谨专业",
      right_text: "深度洞察",
    },
    assets: {
      left_text: "全球投资机会",
      right_text: "专业管理能力",
    },
  },
  newsCenter: {
    title: {
      main1: "一周盘点",
      main2: "百度集团上周美股跌13.91% 港股跌15.65%",
    },
    content: {
      p1: "上周标普500一周涨3.95%，纳斯达克综指涨2.24%，道琼斯工业平均指数涨5.72%。百度美股上周累计跌13.91%，上周总成交额28.26亿美元，截至上周收盘，美股股价为78.54美元，市值为1687.54亿美元；美股本月累...",
    },
    nav: {
      nav1: "企业动态",
    },
    article: {
      title1: "个股走势",
      t1_p1:
        "上周标普500一周涨3.95%，纳斯达克综指涨2.24%，道琼斯工业平均指数涨5.72%。",
      t1_p2:
        "百度美股上周累计跌13.91%，上周总成交额28.26亿美元，截至上周收盘，美股股价为78.54美元，市值为1687.54亿美元；美股本月累计跌幅33.15%，今年累计跌幅47.21%，近52周累计跌幅51.59%",
      t1_p3: "上周恒生指数一周跌8.32%，恒生科技指数跌8.98%。",
      t1_p4:
        "百度集团-SW港股上周累计跌15.65%，上周总成交额48.34亿港元，截至上周收盘，港股股价为75.70港元，市值为2092.60亿港元；港股本月累计跌33.65%，今年累计跌47.65%，近52周跌53.27%。",
      title2: "相关新闻",
      t2_p1: "VR产业盛会即将召开，华为百度等巨头参展",
      t2_p2:
        "据证券时报，2022世界VR产业大会将于11月5日全面筹备就绪，11月12日将在南昌召开。据江西日报，2022世界VR产业大会筹备工作调度推进会发布消息称，预计10月底，大会各项筹备工作基本就绪，11月5日全面就绪。大会正加快推进元宇宙会场、沉浸式空间特效演艺、虚拟数字人等创新场景制作，确保大会期间精彩亮相。2022世界VR产业大会由工信部、江西省人民政府主办，将于11月12日至13日在南昌举行。从2018年开始，世界VR产业大会已连续在江西举办四届。",
      t2_p3: "集度ROBO-01首台验证样车下线 高阶智能驾驶研发已开启路测",
      t2_p4:
        "集度汽车今日在微博宣布，汽车机器人ROBO-01首台验证样车已于近日完成试制，并于吉利汽车研究院试制中心正式下线。另外，集度高阶智能驾驶研发已于10月初开启上海地区的城市域及高速域测试，进入多城场景泛化阶段。",
      title3: "美股评级",
      t3_p1: "10月28日Barclays给予百度增持评级，目标价139.00美元。",
    },
  },
  Statement: {
    title: {
      main: "重要法律信息及使用条款",
      title1: "使用条款",
      title2: "严格地限制于合法的使用及进入",
      title3: "不提供金融服务或产品",
      title4: "不作出保证",
      title5: "风险警示",
      title6: "内容的使用",
      title7: "版权及商标",
      title8: "无推荐建议或意见",
      title9: "不对相关技术或知识产权作出保证",
      title10: "豁免责任",
      title11: "控制范围以外的事件",
      title12: "连结网站",
      title13: "阁下提交的资料",
      title14: "个人资料私隐─如适用",
      title15: "司法权区、可分割性",
    },
    content: {
      t1_p1:
        "务请细阅此等使用条款（「条款」）。本网站（「本网站」）由品森国际（香港）有限公司拥有，并代表品森国际（香港）有限公司及品森国际其他成员公司（统称「公司」）运作及维护。于此等条款中对「公司」的提述亦包括属公司的授权代表或代理的人士及个人。",
      t1_p2:
        "当阁下使用或进入本网站及其任何页面时，即表示阁下已明确地同意此等条款，此等条款可由公司全权酌情不时更改，而无需向阁下发出事先通知。每次阁下进入或使用本网站，阁下受于登入及使用时有效的条款（经修订、补充及更改）约束，而阁下进入及使用本网站亦受该等条款所规限。",
      t2_p1:
        "本网站仅于进入及使用本网站属合法以及在任何方面不受适用法律、法规、行为守则、任何有关证券交易所规则（包括上市规则）或任何监管、执行、中央银行、司法或其他政府机关或机构的规定或命令（统称「适用法律」）限制的情况及司法权区提供予使用者。当阁下使用或进入本网站时，即代表阁下的使用及进入属合法，且阁下承诺阁下仅就合法用途以合法方式使用及进入本网站。",
      t3_p1:
        "公司并不透过本网站提供任何金融服务或产品。本网站中直接或间接所指的任何产品或服务受独立的协议、发售文件及／或申请表格以及公司规定的审批及验收程序（包括适用法律下所规定者）所规限。",
      t3_p2:
        "本网站内对产品或服务的任何提述并不构成公司所提供的任何产品或服务的要约、邀请、招揽、诱使或推荐建议。",
      t3_p3:
        "本网站不应被视为于有关公司获授权进行有关活动以外的任何司法权区（以下称为「获授权国家」）参与投资活动的任何邀请或诱使或买卖任何证券或其他工具的任何要约之传递途径。",
      t3_p4:
        "除非另有所指，本网站内所指的服务及产品并无于获授权国家或阁下所居住国家的任何中央银行、政府或监管机关登记或获其认可。因此，就于或透过本网站所指的产品或服务而言，阁下可能不受阁下居住国家的证券法律、银行法律及其他相关法律法规的保障。",
      t3_p5:
        "概不对在本网站内所讨论或透过本网站得到的任何证券、产品或服务适合阁下或任何个别人士作出任何声明。阁下确认阁下使用本网站及因浏览本网站而作出的任何信息要求并非为公司所征求，而透过本网站提供任何数据并不构成亦不应被视为投资或财务意见。",
      t3_p6:
        "于此等页面所载的数据并无意提供专业意见。进入此等页面的人士于必要时应就有关任何投资或交易的任何决定取得适当的专业意见。",
      t4_p1:
        "尽管于编制本网站所载信息及数据时已审慎行事，有关信息及数据以其「现状」提供，并无作出任何明确或隐含的保证。特别是，概无就有关信息及数据并无侵权、安全、准确性、适用于特定目的或不含计算机病毒作出保证。",
      t4_p2:
        "本网站所指的任何数据及内容或产品或服务可在公司酌情决定下随时被撤回（全部或部分）或修订、更新、更改或移除，而无需发出事先通知。",
      t4_p3:
        "公司于本网站登载的资料代表公司及其人员目前的意见，而有关意见可在无通知下予以更改。资料仅供参考，并不应被视为任何个别证券、策略或投资产品的投资意见或推荐建议。",
      t4_p4:
        "在此所载的若干陈述可能为根据公司目前意见及假设而作出的未来预期陈述及其他前瞻性陈述，以及涉及已知及未知风险及不明朗因素，而可能导致实际结果、表现或事件与在该等陈述中明示或暗示者有重大差异。除在文义上属前瞻性的陈述外，「可、将、应、预期、计划、有意、预计、相信、估计、预测、潜在或持续」等字眼及相类词汇亦可用以识别前瞻性陈述。",
      t5_p1:
        "过往表现并不保证未来业绩，亦不可作为未来业绩的可靠指标。所有投资均存有风险及可损失价值。投资于债券市场需承受若干风险，包括市场、利率、发行人、信贷及通胀风险。股票价值可由于实际及所认为的一般市场、经济及行业状况而下跌。投资于外币计价及／或境外证券可能由于汇率波动及经济和政治风险（于新兴市场可能较高）而涉及较高风险。投资的价值及所得收益可升亦可跌，阁下可能无法收回原始投资额。倘投资涉及货币风险，汇率变动可导致投资价值上升或下跌。本网站内所指的任何税务优惠可予更改，而其可用性及价值将视乎阁下的个别情况而定。有关投资并非任何银行的存款或义务或受任何银行担保或认可，亦无获联邦保险或获任何政府机构、中央银行、法定机关或任何其他人士担保。概不保证于本网站中所讨论的任何投资策略于所有市况下均有效或适合所有投资者，而各投资者应评估他们的长远投资能力，特别是于市场下跌期间。投资者应于作出任何投资决定前咨询其财务及／或税务顾问。本网站中的某些投资及服务于退休储蓄计划中可能并无提供或不适合有关计划。请就与阁下个别计划有关的任何投资限制联络阁下的财务顾问。",
      t6_p1:
        "本网站及本网站所载的任何内容及资料仅供参考，并不构成有关的公司明示或暗示地提供服务或产品。",
      t7_p1:
        "本网站（包括但不限于任何软件、编码、算法及方法）、代管本网站的计算机及本网站中所提供的内容（包括任何文字、音乐和声音、图像、影像、相片、音频和视频、影片、动画、网络广播、分析、研究、报告及下载）（所有有关内容统称「内容」）为公司的专有财产或公司取得许可，并受适用法律（包括香港的数据库、商业秘密及商标法律）保障。「公司」及相关名称、文字、符号、图像及印刷品为公司于香港的商标或注册商标。公司对其于本网站的专有信息或数据保留一切权利，并会在适用版权及商标法律的最大范围内执行有关权利。除此等条款明确规定者外，公司并无向阁下授予任何明确或隐含权利。本网站的所有权利及内容由公司及其有关的许可人拥有。阁下同意在没有公司的明确书面同意下不以任何方式出售、分发、刊发、广播、传阅或商业地利用有关资料。从本网站下载数据或以其他方式复制数据并不会将任何软件或数据的所有权转让予阁下。除非另有指明，公司及于本网站所介绍的其他品牌构成我们的商标／服务标志。阁下同意不会直接或间接：试图登记、怀疑或质疑公司对有关版权、商标或任何公司的任何其他知识产权所有权的有效性或协助第三方作出有关行动。",
      t8_p1:
        "对特定证券及其发行人的提述并无意及不应被诠释为买卖或持有有关证券的推荐建议。公司产品及策略可能或可能不会包括所提述证券，及倘包括有关证券，亦不对将持续包括有关证券作出声明。",
      t9_p1:
        "本网站（包括超链接或对其他网站的提述）所载的数据及意见以其「现状」提供，在适用法律所允许的最大范围内并无作出任何明确或隐含的保证。阁下负责评估本网站所提供的任何数据或内容的足够性、准确性、可靠性、适销性、非侵权性、完整性或网站或其任何内容对任何特定目的的合适性。公司亦不对本网站所载功能将为不间断或无误、不足之处将加以改正或本网站或支持本网站运作的服务器不含病毒或其他有害组件承担任何责任及作出任何保证。",
      t10_p1:
        "公司或其各自的任何联属公司、董事、高级职员、注册代表或雇员或任何第三方卖方在合约、侵权行为、严格责任或其他方面均无需就阁下进入或使用或未能进入或使用本网站或倚赖内容或与本网站有关的性能故障、中断、不足之处、传输延误、计算机病毒或其他有害组件或线路或系统故障（不论我们是否知悉）所产生或在任何方式上有关的任何直接、间接、附带、随之发生、惩罚性或特殊损害承担或有任何法律责任。",
      t11_p1:
        "阁下明确地免除及解除公司、其各自的联属公司、相关公司及其各自所有的高级职员、董事、雇员、注册代表、股东、法定代表、律师、代理、继任人及受让人因其控制范围以外的原因（包括但不限于电子或机械设备或通讯线路故障、电话或其他互连问题、计算机病毒或软件故障、未经授权下存取、盗窃、操作员错误、恶劣天气、地震或天灾、罢工或其他劳工问题、战争、国内或国外的恐怖主义活动或政府限制）而造成伤害的索偿。",
      t12_p1:
        "本网站内若干超链接或所提述网站可能是为阁下方便起见而将阁下转至第三方网站，该等网站一般可透过其高级域名辨认得到。除非公司明确列明，否则对其他产品、刊物或服务的任何描述、提述或连结并不构成公司对任何连结网站或其赞助人的认可、授权、赞助或联系。任何有关数据、产品或网站并不一定经公司审阅及由公司并无控制权的第三方提供或维持。公司明确地拒绝就该等第三方网站所提供或宣传的内容、数据准确性及／或产品或服务质素承担任何责任。公司保留权利随时终止任何超链接或超链接程序。",
      t13_p1:
        "阁下不应而公司亦不接受透过本网站或任何论坛提交任何机密或专属资料。透过本网站提交的所有信息或数据将被视为公司财产。透过本网站将任何信息或数据提交予我们，即代表阁下向我们授予无限制、不可撤回许可，允许我们使用、复制、展示、执行、修改、传输及分发该等数据或信息。阁下亦同意公司可自由就任何目的使用阁下透过本网站向我们提交的意念、概念、专有技术或技巧。公司无需就所提交信息受任何保密责任所规限，惟经公司同意有直接客户关系或以其他方式特别协议或适用法律所规定者则除外。",
      t14_p1:
        "倘阁下向公司提供任何个人资料，公司的私隐政策将适用，阁下将获提供该政策副本。",
      t15_p1:
        "此等条款或本网站所产生的任何诉讼必须及仅可在中华人民共和国香港特别行政区（「香港」）法院提出，且阁下同意受香港法院的专有司法管辖权管辖，并进一步同意该等法院对阁下而言属方便。倘此等条款的任何条文不能强制执行，余下条文的有效性或强制执行性并不会受影响，而不能强制执行条文将以与不能强制执行条文意向接近的可强制执行条文所取代。",
    },
  },
  Privacy: {
    title: {
      main1: "用户私隐政策",
      main2: "用户隐私政策（中国大陆）",
      title1: "1. 序言",
      title2: "2. 我们何时收集何种个人资料？",
      title3: "3. 我们将个人资料作何种用途？",
      title4: "4. 我们如何使用小型文本文件 (cookies)",
      title5: "5. 我们是否会将个人资料用于直接促销？",
      title6: "6. 我们向何人披露个人资料？",
      title7: "7. 如何确保个人资料安全？",
      title8: "8. 查阅及更正/查询您的个人资料",
      title9: "个人资料收集声明",
    },
    content: {
      t1_p1:
        "品森国际（香港）有限公司（「我们」或「品森国际」）致力根据香港《个人资料（私隐）条例》(“私隐条例”)中载列的规定保护个人资料。",
      t1_p2:
        "我们将仅按照《个人资料（私隐）条例》及本私隐政策收集、使用或披露个人资料。",
      t1_p3: "我们可出于任何原因随时对本私隐政策进行修改。",
      t1_p4: "在本私隐政策中，「个人资料」指符合以下说明的任何资料：",
      t1_p5: "直接或间接与一名在世的个人有关的；",
      t1_p6:
        "该项资料是切实可行的用于直接或间接地确定有关个人的身份；及该项数据的形式是切实可行的让人可查阅及处理该项数据。",
      // t1_p7: ' 该项数据的形式是切实可行的让人可查阅及处理该项数据。',
      t2_p1:
        "我们收集的个人资料的类型将视乎所收集资料的具体情况而定。倘若您未有提供我们所要求的个人资料，我们未必能够向您提供或继续提供产品和服务。",
      t2_p2: "我们可在以下情况下收集关于您的个人资料:",
      t2_p3:
        "•使用我们的网络平台时──包括您的身份数据和联络详情、生物识别资料、金融资料、您的收入和现有投资；",
      t2_p4: "•向我们申请开设账户时──包括您的金融资料、收入及现有投资资料；",
      t2_p5:
        "•是在我们开设的账户的企业签署人、董事、高级人员或担保人时──包括您的身份资料和联络详情；",
      t2_p6:
        "•应聘我们的职位时──包括您的身份数据和联络详情、您的技能、您可能存在的任何残疾或身体状况、发放工资的银行账户资料、购买医疗保险所需的家庭成员详情、您的犯罪记录数据及与我们的合规义务相关的其他数据；或",
      t2_p7: "•向我们发送信函时──包括您的详细联络方式，以便我们回复您。",
      t3_p1: "您的个人资料的用途将视乎所收集资料的具体情况而定。",
      t3_p2:
        "我们将于收集您的个人资料之时或之前在「个人资料收集声明」中告知您我们将使用您的个人资料作何用途。",
      t3_p3: "一般而言，我们会将您的个人资料用于：",
      t3_p4: "•您向我们提供个人资料的目的；",
      t3_p5: "•与您向我们提供个人资料之目的直接相关的用途；",
      t3_p6: "•您所同意的任何其他用途；及",
      t3_p7:
        "•遵守对我们产生约束力的任何法律及法规，以及任何法律、监管、政府、税务、执法或其他机关，或与我们及我们的产品和服务有关的自我监管或行业组织或协会发出的任何指引或通知。",
      t3_p8: "例如，我们可能在以下情况下透露您的个人资料：",
      t3_p9:
        "•使用我们的网络平台时──用于处理和评估您的申请、开设和管理账户、核实您的身份、为您提供服务、进行信用审查和向您提供信贷融通、协助其他金融机构进行信用审查、向您收取及支付款项、为您的责任提供担保、设计新的金融服务和产品、侦测、调查及预防诈骗或刑事活动，以及作出法律规定或允许的任何披露或转让；",
      t3_p10:
        "•向我们申请开设账户时──用于处理和评估您的申请、开设和管理账户、为您提供服务、进行信用审查和向您提供信贷融通、协助其他金融机构进行信用审查、向您收取及支付款项、为您的责任提供担保、设计新的金融服务和产品，以及作出法律规定或允许的任何披露或转让；",
      t3_p11:
        "•是在我们开设的账户的企业签署人、董事、高级人员或担保人时──用于将您注册为签署人或担保人并遵守法律规定；",
      t3_p12:
        "•应聘我们的职位时──用于评估您的申请、管理薪酬、福利和税务、表现评估、晋升、处理纪律问题、编制应变计划、培训、人才招聘、多样性规划、向第三方提供推荐信、员工内部重组，以及遵守法律规定，以及作出法律规定或允许的任何披露或转让；及",
      t3_p13: "•向我们发送信函──用于回复您。",
      t4_p1:
        "通过进入我们的网络平台，您承认您已被告知使用小型文本文件 (cookies)的常规，并授权我们就本私隐政策所设定的目的使用通过我们使用小型文本文件 (cookies)所收集的任何资料。“小型文本文件 (cookies)”是当您进入某些网站或电话应用程序后储存在您的电子设备（例如计算机或电话）的数据。",
      t4_p2:
        "小型文本文件 (cookies) 主要用于当访客返回同一个网站时，他们不需要提供先前已经提供予该网站的某些信息。小型文本文件 (cookies) 也可用来收集显示一个网站的领域是否常被浏览的数据。保留有关网站经常被浏览的领域的数据可允许操作员更好地规划和提升该网站。",
      t4_p3:
        "我们明白您可能会希望禁用小型文本文件。您可以透过改变您的浏览器设定来禁用小型文本文件 (cookies)，但此举可能会限制更多功能。但是，如果您这样做，您可能无法使用或启动我们的网络平台上的某些功能。",
      t5_p1:
        "我们会透过电话营销、电子形式、直接邮寄或其他适当的手段将收集的部分个人资料用于向预期接收者发送市场推广材料及特别优惠信息，但我们仅会在遵守私隐条例所规定有关直接促销的规则前提下使用个人资料。",
      t5_p2:
        "若我们有意将您的个人资料用于直接促销或提供您的个人资料以供第三方用于直接促销，我们将会于收集您的个人资料之时或之前在「个人资料收集声明」中告知您。届时，您可以选择拒绝接收直接促销信息。",
      t5_p3:
        "若当时您未选择拒绝接收直接促销信息，但其后确定不希望接收直接促销信息，您可以联络数据保护专员，要求我们停止向您发送任何进一步直接促销信息。",
      t6_p1: "您的个人资料将获被披露到的第三方将视乎个人资料的用途而定。",
      t6_p2:
        "我们将于收集您的个人资料之时或之前，在「个人资料收集声明」中告知您我们将向其披露您的个人资料的第三方。",
      t6_p3: "一般而言，我们会就以下目的按需要披露您的个人资料：",
      t6_p4: "•您向我们提供个人资料的目的；",
      t6_p5: "•与您向我们提供个人资料之直接相关的目的；及",
      t6_p6: "•您所同意的任何其他目的。",
      t6_p7: "例如，我们可能会向以下人士披露您的个人资料：",
      t6_p8:
        "•提供行政管理、通讯、计算机、支付、保安或其他服务以协助我们实现上述目的之服务的第三方代理人、承办商及顾问（包括电话销售员、寄件中心、信息科技服务供货商及数据处理人员）；",
      t6_p9: "•我们的法律及专业顾问；",
      t6_p10: "•我们的关连公司（定义见香港《公司条例》）；",
      t6_p11:
        "•对我们或我们的关连公司具约束力的任何法律、法规、规则或准则所规定之政府机构及相关当局；及",
      t6_p12: "•您所同意的任何其他人士。",
      t6_p13:
        "当个人资料就此类目的被转移到香港以外的地方时，该地方可能会或可能不会提供与香港相同或类似的个人资料保护。",
      t7_p1:
        "我们将采取一切切实可行的步骤，以确保您的个人资料不会被擅自查阅、披露、处理、删除、丢失或使用。",
      t8_p1:
        "您可以联络我们请求查阅或更正或查询我们所持有的关于您的个人资料。《个人资料（私隐）条例》中的若干豁免情况适用于查阅及更正个人资料的要求。我们可能会要求您（即要求查阅或更正数据的人士）提供适当的身份证明，并且可能会收取合理的行政费用，方会批准您查阅资料的要求。",
      t8_p2:
        "若需要查阅或更正或查询我们所持有的个人资料，请透过以下方式联络我们：",
      t8_p3: "数据保护专员",
      t8_p4: "品森国际",
      t8_p5: "香港地址：香港九龙湾宏照道39号企业广场3期39楼",
      // 香港湾仔告士打道38号中国恒大中心18楼1803-1806室
      t9_p1:
        "品森国际（香港）有限公司（「我们」或「品森国际」）可不时出于以下目的使用我们所收集到有关于您的个人资料：",
      t9_p2: "",
      t9_p3: "•办理及评估您的账户申请；",
      t9_p4: "•为您开设及管理账户；",
      t9_p5: "•核实您的身份；",
      t9_p6: "•侦测、调查及预防诈骗或刑事活动；",
      t9_p7: "•提供财富管理及投资服务；",
      t9_p8: "•进行信用审查及提供信贷融通；",
      t9_p9: "•协助其他金融机构进行信用审查；",
      t9_p10: "•厘定欠付您或您欠付的未偿付金额；",
      t9_p11: "•向您收取款项及未偿付金额；",
      t9_p12: "•向您支付股息、利息、收益及其他金额；",
      t9_p13: "•为您的责任提供担保；",
      t9_p14: "•出于以上任何目的联络您；",
      t9_p15: "•设计新金融服务及产品；",
      t9_p16:
        "•根据对我们或我们的关连公司具约束力的任何法律、法规、规则或准则规定作出披露",
      t9_p17: "•与上述目的直接相关的其他次要目的；及",
      t9_p18: "•您所同意的任何其他用途。",
      t9_p19:
        "在您同意的情况下，我们还可使用您的联络详情、人口统计信息及投资详情，以邮件、电子邮件、电话、短讯、实时通讯或推播通知方式向您发送有关金融及投资产品或服务的市场推广通讯。我们需要您的同意。您可以以任何退出选项表明您不同意接收此类市场推广通讯。",
      t9_p20:
        "在您同意的情况下，我们还可将您的联络详情、人口统计信息及投资详情提供予我们关联公司,保险公司及/或投资经纪（当中可能获得收益），他们可能以邮件、电子邮件、电话、短讯、实时通讯或推播通知方式向您发送有关金融及投资产品或服务的市场推广通讯。我们需要您的同意。您可以以任何退出选项表明您不同意我们将您的个人资料提供给出了我们关联公司之外的第三方，或不希望接收来自此类第三方市场推广通讯。",
      t9_p21:
        "我们亦会不时使用你的名称、联系方式、产品和服务、投资详情、投资组合信息、交易模式及行为、财务背景和人口统计数据作我们、我们的关联公司和我们的业务合作伙伴可能提供的有关以下类别的产品及服务(包括提供奖赏、忠诚或优惠计划)之直接促销: a) 保险、年金、金融、财富管理、退休计划、投资和金融产品及服务、信用卡、外汇、衍生工具、证券及相关产品及服务; 及b) 健康、保健和医疗、食品和饮料、媒体、网络购物、电子商务、游戏、博彩、物流、体育活动、会员及相关产品和服务。",
      t9_p22: "我们可就上述目的将您的个人资料披露予：",
      t9_p23:
        "•提供行政管理、通讯、计算机、支付款项、保安或其他服务以协助我们实现上述目的之第三方代理人、承办商及顾问（包括电话销售员、寄件中心、信息科技服务供货商及数据处理人员）；",
      t9_p24: "•您与之或打算与之进行交易的任何金融机构；",
      t9_p25: "•我们与您相关的权利的实际或建议承让人、受让人或参与者；",
      t9_p26: "•我们的法律及专业顾问；",
      t9_p27: "•我们的关连公司（定义见香港《公司条例》）；",
      t9_p28:
        "•我们的商业伙伴及/或其关联公司提供的上述产品和服务,包括奖赏、忠诚或优惠计划的第三方提供商; 支持我们或任何上述实体提供上述产品和服务的外部服务提供商;",
      t9_p29:
        "•对我们或我们的关连公司具约束力的任何法律、法规、规则或准则所规定之政府机构及相关当局；及",
      t9_p39: "•您所同意的任何其他人士。",
      t9_p30:
        "除了上述产品及服务的市场营销, 我们还可将您的上述个人资料提供及/或传递予上述之全部或任何实体或人员,他们可能以邮件、电子邮件、电话、短讯、实时通讯或推播通知方式向您发送市场推广通讯。我们需要您的同意。您可以以任何退出选项表明您不同意我们将您的个人资料提供给此类第三方，或不希望接收来自此类第三方市场推广通讯。在某些情况下，我们或我们向其披露您的个人资料的第三方可在香港以外地区处理及储存您的个人资料。",
      t9_p31:
        "就网络平台（包括任何移动应用程序及网站），某些字段将标明可选择是否提供个人资料之项目。倘若您未提供必填栏目要求填写的个人资料，我们将无法向您提供服务。",
      t9_p32:
        "除非文义另有说明或另有规定，否则本个人资料收集声明中使用的黑体字词语应与本协议中定义的含义相同。",
      t9_p33:
        "您可透过以下联络方式，要求查阅或要求更正我们所持有的关于您的任何个人资料：",
      t9_p34: "数据保护专员",
      t9_p35: "品森国际",
      t9_p36: "香港地址：",
      // 香港湾仔告士打道38号中国恒大中心18楼1803-1806室
      t9_p37: "版本发布日期：2022年11月10日",
      t9_p38: "版本生效日期：2022年11月10日",
    },
    // 中国大陆
    title2: {
      t1: "一、我们如何收集您的个人信息",
      t2: "二、我们将如何使用收集的信息",
      t3: "三、我们如何使用Cookie和同类技术",
      t4: "四、我们如何共享、 公开披露您的个人信息",
    },
    // part 0
    content2_0: {
      t0_p1:
        "品森国际（香港）有限公司（以下称“品森国际”或“我们”）尊重并保护用户（以下简称“您”）的隐私，在您使用品森国际相关产品及服务（以下简称“品森服务”）时，我们将按照本《品森国际用户隐私政策（中国大陆）》（以下简称“本隐私政策”或“本政策”）收集、存储、使用和共享提供您的个人信息。本隐私政策仅适用于使用品森服务的中华人民共和国（以下简称“中国”，为本隐私政策目的，不包括香港、澳门和台湾）境内自然人用户。",
      t0_p2:
        "本政策所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本政策涵义解释的依据。",
      t0_p3:
        "您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按适用法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，在使用品森服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用品森服务，即表示您已充分理解并同意本政策。",
      t0_p4: "本隐私政策将帮助您了解一下内容：",
      t0_p5: "1、我们如何收集您的个人信息",
      t0_p6: "2、我们如何使用收集的信息",
      t0_p7: "3、我们如何使用Cookie和同类技术",
      t0_p8: "4、我们如何共享、公开披露您的个人信息",
      t0_p9: "5、我们如何保护您的个人信息",
      t0_p10: "6、我们如何存储信息",
      t0_p11: "7、您管理自己信息的权利",
      t0_p12: "8、我们如何保护未成年人的信息",
      t0_p13: "9、我们可能向您发送的信息",
      t0_p14: "10、您分享的信息",
      t0_p15: "11、本政策如何更新",
      t0_p16: "12、适用法律及争议解决",
      t0_p17: "13、其他14、如何联系我们",
      t0_p18:
        "我们一贯重视用户信息安全与隐私保护，并承诺将严格遵守法律法规，为您提供更加安全、可靠的服务",
      t0_p19:
        "我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。",
      t0_p20:
        "为了帮助您更好的了解本隐私政策，将相关条款中的部分名词进行解释如下：",
      t0_p21:
        "1、个人信息：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。",
      t0_p22:
        "2、敏感个人信息：指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。",
      t0_p23:
        "3、品森关联方：指品森国际直接或间接控制的任何其他法人，或者直接或间接地控制品森国际或与品森国际共同受控制于他人的任何其他法人。",
      t0_p24:
        "4、品森平台：指我们运营的服务平台，包括且不限于网络平台（网址为：【】）以及其他服务页面等，以及包括通过对接我们的合作机构的方式（包括但不限于链接跳转H5、应用程序编程接口API、软件开发工具包SDK或者前台业务系统前置等方式），为您直接或间接提供相关服务。",
      t0_p25:
        "5、品森服务：指我们通过品森平台等各种方式向您提供的信息展示、信息管理与技术支持及其他用户使用的由品森国际提供的服务。",
    },
    //t1: '一、我们如何收集您的个人信息',
    content2_1: {
      // title1_section1_part1
      t1_s1_p1:
        "1. 为了向您提供服务，维护品森服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用产品及服务时主动提供、授权提供或基于您的要求提供的信息，以及基于您使用品森服务时产生的信息：",
      t1_s1_p2:
        "（1） 出于处理和评估您的申请、开设和管理账户、核实您的身份、为您提供服务，在您使用我们的网络平台时，我们会收集您的身份数据和联络详情、生物识别资料、金融资料、您的收入和现有投资资料；如您不同意上述内容，可能会导致您无法开立相关账户及您对我们网络平台基本服务的使用，进而影响到您享有相关权益及服务。",
      t1_s1_p3:
        "（2） 当您使用手机号码或电子邮箱注册品森服务时，我们会收集您的手机号码和电子邮箱并校验验证码，收集此类信息是为了满足相关法律法规的网络实名制要求。",
      t1_s1_p4:
        "（3） 当您使用品森服务个人头像编辑功能时，如果选择使用“拍照”或“拍摄”方式，品森服务会使用设备的相机权限；如果使用“从相册选择”的方式，品森服务会使用设备的照片权限。您在需要时将品森服务中发现的有用图片保存至相册中也会使用到设备的照片权限。您上传的照片、录像信息会存储在我们的服务器中，因为存储是实现这一功能所必需的。",
      t1_s1_p5:
        "（4） 当您使用品森服务行情系统的提醒或重大事项预告功能时，品森服务会使用设备的日历权限，以便您及时获取相关信息。如果拒绝访问，您将无法使用提醒功能，但不影响您使用品森服务的其他服务。",
      t1_s1_p6:
        "（5） 为保证您能正常领取和使用有关行情权益及服务，我们会对您提供的客户编号、IP地址和头像、昵称等用以进行地域或账户有效性识别。如您不同意上述内容，可能会影响地域或账户的有效性判断，进而影响到您领取和使用有关行情权益及服务。",
      t1_s1_p7:
        "（6） 当您通过品森服务接入券商开户功能时，品森服务会使用设备的摄像头和麦克风权限，拒绝使用摄像头和麦克风权限，开户流程将无法正常完成。根据券商及相关证券法律要求，在您通过品森服务申请开户时，必须提交身份证正反面照片、证明客户实名开户及开户意愿的视频（单向或双向），以及您的通讯地址、您所在的工作单位及职业职位等信息。若您不同意上述内容，您将无法使用开户功能，但不影响您使用品森服务的其他服务。",
      t1_s1_p8:
        "（7） 当您使用品森服务时，为保障您正常使用我们的服务，且为向您提供更契合您需求的页面展示、了解产品适配性、识别账号异常状态，以及改进和优化我们的服务体验及保障您的账号安全的需要，我们会收集关于您使用的服务及使用方式的信息并将这些信息进行关联，这些信息包括：",
      t1_s1_p8_1:
        "（i）日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的登录账号、搜索查询内容、IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页浏览记录、Push打开记录、停留时长、刷新记录、发布记录、关注及分享。我们会获取您设备的存储权限，用于保存前述日志信息。",
      t1_s1_p8_2:
        "（ii）设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如IMEI、MAC、Serial、SIM卡IMSI识别码、设备机型、操作系统及版本、客户端版本、设备分辨率、包名、设备设置、唯一设备标识符、软硬件特征信息）、设备所在位置相关信息（例如IP 地址、GPS位置以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。",
      t1_s1_p9: "（8） 经您授权或同意收集的其他必要信息。",
      t1_s2_p1:
        "2. 另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意：",
      t1_s2_p1_1: "（1）与国家安全、国防安全等国家利益直接相关的；",
      t1_s2_p1_2:
        "（2）与公共安全、公共卫生、公众知情等重大公共利益直接相关的；",
      t1_s2_p1_3: "（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；",
      t1_s2_p1_4:
        "（4）出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的；",
      t1_s2_p1_5:
        "（5）所收集的个人信息是您自行向社会公众公开的，且您并未明确拒绝我们收集、使用该信息的;",
      t1_s2_p1_6:
        "（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道，且该信息您并未明确拒绝我们收集、使用；",
      t1_s2_p1_7: "（7）根据您要求签订和履行合同所必需的；",
      t1_s2_p1_8:
        "（8）用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；",
      t1_s2_p1_9: "（9）为开展合法的新闻报道所必需的；",
      t1_s2_p1_10:
        "（10）出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；",
      t1_s2_p1_11: "（11）法律法规规定的其他情形。",
      t1_s3_p1:
        "3. 请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未在前述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告、邮件通知等方式另行向您说明信息收集的方式、内容、范围和目的。",
      t1_s4_p1:
        "4. 我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。我们将在符合相关法律规定，并依据与关联方或者第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。",
      t1_s5_p1:
        "5. 敏感个人信息的处理：在您使用品森服务时，我们可能根据适用法律和业务需要按照本政策收集和处理你的敏感个人信息。我们仅在向你提供我们产品和服务所必要的情况下、且仅在你单独同意的情况下处理你的该等信息。如果您选择不提供该等信息或不同意我们处理该等信息，我们可能无法向你提供某些特定产品或服务。上述部分已经标明了我们所将收集的敏感信息和对应的收集目的。如果您不同意收集该些敏感信息，我们可能无法实现相应的服务或功能。您使用隐私服务并确认本隐私政策即表示已同意我们处理上述约定的敏感个人信息。",
      t1_s5_p1:
        "如我们需要向您收集和处理本隐私政策未载明的其他敏感个人信息的，我们将根据适用法律的要求，依法向您告知我们收集您敏感个人信息的处理目的和处理方式，以及其他依据法律需要告知的内容，并征求您的单独同意。",
    },
    // t2: '二、我们将如何使用收集的信息'
    // content2_2: {
    //   t2_p0:
    //     "基于国家相关法律法规的规定，以及为向您提供服务及提升服务质量、向您提供安全、顺畅、高效和个性化的体验的目的，我们将在严格遵守法律法规的规定、本隐私政策及其他与您的约定的前提下，将收集的信息用于以下用途。",
    //   t2_p1: "1、向您提供各项品森服务；",
    //   t2_p2: "2、使我们了解您如何接入和使用相关服务，以满足您的个性化需求；",
    //   t2_p3:
    //     "3、开发和服务优化。例如，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务；",
    //   t2_p4:
    //     "4、向您发送营销信息推广品森服务服务或第三方商品和服务，推荐您感兴趣的信息和资讯以及发出与品森服务服务有关的通知；",
    //   t2_p5: "5、评估、改善我们的推广效果等；",
    //   t2_p6: "6、管理软件。例如，进行软件认证、软件升级等；",
    //   t2_p7: "7、邀请您参与有关我们服务的调查；",
    //   t2_p8:
    //     "8、预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，保护您、其他用户或公众以及我们或我们关联公司的合法权益，我们会使用或整合您的个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施；",
    //   t2_p9:
    //     "9、为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息，例如，您使用应用程序的频率、崩溃数据、总体使用情况、性能数据以及应用程序的来源，但我们不会将我们存储在分析软件中的信息与您在应用程序中提供的个人身份信息相结合；",
    //   t2_p10: "10、经您授权或同意的其他用途。",
    //   t2_p11:
    //     "如我们使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我们将在使用您的个人信息前，再次向您告知并征得您的明示同意。",
    // },
    //t3: '三、我们如何使用Cookie和同类技术'
    content2_3: {
      t3_s1_p1: "1、Cookie",
      t3_s1_p2:
        "为使您获得更轻松的访问体验，您使用品森服务时，我们可能会通过采用各种技术收集和存储您访问品森服务的相关数据，在您访问或再次访问品森服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务，包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止Cookie 的功能。但如果您这么做，在某些情况下您可能无法使用依赖于cookies的品森服务的部分功能，则需要在每一次访问我们的网站时亲自更改用户设置。",
      t3_s2_p1: "2、网站信标和像素标签",
      t3_s2_p2:
        "除 Cookie 外，我们还可能在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。",
      t3_s3_p1: "3、Do Not Track（请勿追踪）",
      t3_s3_p2:
        "很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们会尊重您的选择。",
    },
    // t4: '四、我们如何共享、 公开披露您的个人信息'
    content2_4: {},
  },
  Contact: {
    content: {
      company: "品森国际（香港）有限公司",
      address: "香港九龙湾宏照道39号企业广场3期39楼",
    },
  },
  New: {
    sub_title: "品森金融",
    title1: "美股冬令时交易时间切换提醒",
    title2: "品森国际举办2023年度第一季度投资策略研讨会",
    title3: "3月12日起美国进入夏令时，美股开盘时间提前至21:30",
    title4: "美元及美国国债收益率上升 国际金价下跌",
    title5: "鲍威尔：美联储坚定致力于实现2%的目标",
    title6: "港交所上半年IPO数量增募资减",
    title7: "北美地区已开始实行夏令时 美股将于21:30开盘",
    title8: "美联储古尔斯比：预计今年将降息三次",
    title9: "港股需等待更多积极信号，建议仍以高股息策略为主",
    con_p1:
      "<p>尊敬的客户，美国将于11月6日（周日）开始冬令时，交易时间调整如下：</p><p>美股交易时间为北京时间22:30至次日凌晨5:00；</p><p>盘前交易时间为北京时间17:00至22:30；</p><p>盘后交易时间为北京时间05:00至09:00；</p><p>美国期货交易时间也对应调整为延后1小时。</p><p>敬请知悉。</p>",
    con_p_title1:
      "<p>尊敬的客户，美国将于11月6日（周日）开始冬令时，交易时间调整如下：</p><p>美股交易时间为北京时间22:30至次日凌晨5:00；</p><p>盘前交易时间为北京时间17:00至22:30；</p><p>敬请知悉。</p>",
    con_p2:
      "<p>为提升对广大机构投资者的投研服务质量，品森国际于1月8日在香港举行了2023年度第一季度证券投资策略研讨会。会议由刘总主持，首席行业分析师及几位资深研究员分别作了各行业2023年度第一季度的策略研究报告，报告会获得圆满成功。</p>",
    con_p_title2:
      "<p>为提升对广大机构投资者的投研服务质量，品森国际于1月8日在香港举行了2023年度第一季度证券投资策略研讨会。会议由刘总主持，首席行业分析师及几位资深研究员分别作了各行业2023年度第一季度的策略研究报告，报告会获得圆满成功。</p>",
    con_p3:
      "<p>3月12日（周日）起，美国进入夏令时，美股提前一小时开盘。</p><p>具体交易时间调整如下：</p><p>美股持续交易时间调整为21:30-次日04:00（北京时间）</p><p>盘前交易为16:00-21:30（北京时间）</p><p>盘后交易为04:00-08:00（北京时间）</p>",
    con_p_title3:
      "<p>3月12日（周日）起，美国进入夏令时，美股提前一小时开盘。</p><p>具体交易时间调整如下：</p><p>美股持续交易时间调整为21:30-次日04:00（北京时间）</p>",
    con_p_title4:
      "<p>美元及美国国债收益率上升，国际金价下跌。纽约期金收报每盎司2007美元，下跌约0.4%。</p><p>利率期货显示，联储局在5月加息25个基点的可能性为86%。</p>",
    con_p5:
      "<p>美联储主席鲍威尔：通胀目前远远超过美联储的目标，通货膨胀对社会边缘人群造成重大困难。美联储坚定致力于实现2%的目标；未能实现将会造成更大的伤害。</p",
    con_p5_sub:
      "<p>美联储主席鲍威尔：通胀目前远远超过美联储的目标，通货膨胀对社会边缘人群造成重大困难。美联储坚定致力于实现2%的目标；未能实现将会造成更大的伤害。</p",
    con_p_title5:
      "<p>美联储主席鲍威尔：通胀目前远远超过美联储的目标，通货膨胀对社会边缘人群造成重大困难。</p><p>美联储坚定致力于实现2%的目标；未能实现将会造成更大的伤害。</p>",
    con_p_title6:
      "<p>上半年进入尾声。Choice数据显示，年初至今，港股IPO数量为28只（包括介绍上市），募资总额为162.62亿港元，同比减少14.63%。2022年同期，港股有22只新股上市（包括介绍上市），募资总额达190.49亿港元。</p>",
    con_p6:
      "<p>上半年进入尾声。Choice数据显示，年初至今，港股IPO数量为28只（包括介绍上市），募资总额为162.62亿港元，同比减少14.63%。2022年同期，港股有22只新股上市（包括介绍上市），募资总额达190.49亿港元。</p><p>展望下半年，业内人士认为，多家互联网巨头分拆子业务独立上市、特专科技公司陆续上市将成港股IPO重要力量。</p><p>普华永道香港资本市场服务合伙人黄金钱预计，今年或有5家至10家特专科技公司按照新规上市，平均募资约50亿港元。</p>",
    con_p6_sub:
      "<p>上半年进入尾声。Choice数据显示，年初至今，港股IPO数量为28只（包括介绍上市），募资总额为162.62亿港元，同比减少14.63%。2022年同期，港股有22只新股上市（包括介绍上市），募资总额达190.49亿港元。</p><p>展望下半年，业内人士认为，多家互联网巨头分拆子业务独立上市、特专科技公司陆续上市将成港股IPO重要力量...</p>",
    con_p_title7:
      "<p>北美地区已开始实行夏令时，美国和加拿大金融市场交易时间（金银、美油、美股等）和经济数据公布时间将较冬令时提前一小时，即金银、美油将于北京时间6:00开盘，美股将于北京时间21:30开盘，次日04:00收盘。</p>",
    con_p7:
      "<p>北美地区已开始实行夏令时，美国和加拿大金融市场交易时间（金银、美油、美股等）和经济数据公布时间将较冬令时提前一小时，即金银、美油将于北京时间6:00开盘，美股将于北京时间21:30开盘，次日04:00收盘。</p>",
    con_p7_sub:
      "<p>北美地区已开始实行夏令时，美国和加拿大金融市场交易时间（金银、美油、美股等）和经济数据公布时间将较冬令时提前一小时，即金银、美油将于北京时间6:00开盘，美股将于北京时间21:30开盘，次日04:00收盘。</p>",
    con_p_title8:
      "<p>芝加哥联邦储备银行行长奥斯丁·古尔斯比周一表示，在上周的联邦储备委员会政策会议上，他预计今年将有三次降息。在那次会议上，美联储将基准隔夜贷款利率维持在5.25%-5.5%的目标区间，而政策制定者预计今年降息的中位数为3。古尔斯比在接受媒体采访时表示：“我的预测处...</p>",
    con_p8:
      "<p>芝加哥联邦储备银行行长奥斯丁·古尔斯比周一表示，在上周的联邦储备委员会政策会议上，他预计今年将有三次降息。在那次会议上，美联储将基准隔夜贷款利率维持在5.25%-5.5%的目标区间，而政策制定者预计今年降息的中位数为3。</p><br/><p>古尔斯比在接受媒体采访时表示：“我的预测处于中位数。”但他拒绝透露他认为借贷成本可能何时开始放松。古尔斯比补充说，一月和二月的价格上涨数据高于预期，“主要难题”仍然是住房通胀。</p><br/><p>古尔斯比说：“所以我们处于一个不确定的状态，但我觉得我们并没有从根本上改变我们正在回到目标的情况。”</p>",
    con_p8_sub:
      "<p>芝加哥联邦储备银行行长奥斯丁·古尔斯比周一表示，在上周的联邦储备委员会政策会议上，他预计今年将有三次降息。在那次会议上，美联储将基准隔夜贷款利率维持在5.25%-5.5%的目标区间，而政策制定者预计今年降息的中位数为3。</p><p>古尔斯比在接受媒体采访时表示：“我的预测处于中位数。”但他拒绝透露他认为借贷成本可能何时开始放松。古尔斯比补充说，一月和二月的价格上涨数据高于预期，“主要难题”仍然是住房通胀...</p>",
    con_p_title9:
      "<p>天风证券发布研究报告称，港股市场方面，在经济数据持续性等待验证以及港元贬值压力尚未缓解的背景下，建议仍以高股息策略为主。短期内股息率较高的公用事业、能源、金融、电信等板块，即使未来市场波动率有所上升，也能在该环境中提供可观的相对收益；中长期而言，以半...</p>",
    con_p9:
      "<p>天风证券发布研究报告称，港股市场方面，在经济数据持续性等待验证以及港元贬值压力尚未缓解的背景下，建议仍以高股息策略为主。短期内股息率较高的公用事业、能源、金融、电信等板块，即使未来市场波动率有所上升，也能在该环境中提供可观的相对收益；中长期而言，以半导体、互联网为代表的科技行业仍将是产业转型的主抓手，有望从政府支持以及国产替代中受益。</p>",
    con_p9_sub:
      "<p>天风证券发布研究报告称，港股市场方面，在经济数据持续性等待验证以及港元贬值压力尚未缓解的背景下，建议仍以高股息策略为主。短期内股息率较高的公用事业、能源、金融、电信等板块，即使未来市场波动率有所上升，也能在该环境中提供可观的相对收益；中长期而言，以半导体、互联网为代表的科技行业仍将是产业转型的主抓手，有望从政府支持以及国产替代中受益。</p>",
  },
};

export default ch;
